<template>
  <v-app id="dashboard">
    <v-app-bar app clipped-left color="blue-grey darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>ShairMail</v-toolbar-title>
      <!-- <v-text-field
        v-model="search"
        solo
        dense
        light
        rounded
        max-width="450"
        class="mx-5"
        hide-details="true"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field> -->

      <div class="ml-5">
        Welcome, {{ userInfo.firstName }} {{ userInfo.lastName }}
      </div>
      <v-spacer></v-spacer>

      <v-tooltip class="ml-5" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="logout()" large v-on="on">
            <v-icon> mdi-logout </v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      class="blue-grey"
      dark
      style="overflow-y: auto"
      clipped
      v-model="drawer"
      app
    >
      <v-layout full-width fill-height class="flex-column">
        <v-btn
          color="white"
          class="mt-8 ma-2 blue-grey--text"
          @click="$router.push('/compose')"
        >
          <v-icon left dark> mdi-pencil </v-icon>
          Compose
        </v-btn>

        <v-list dense>
          <v-subheader>MailBoxes</v-subheader>
          <v-list-item-group mandatory v-model="selectedMainNav">
            <v-list-item
              v-for="(item, i) in mainNavItems"
              :key="i"
              @click="$router.push(`/mail/${item.text}`)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-list v-if="groupNavItems.length > 0" dense style="overflow-y: auto">
          <v-subheader>Groups</v-subheader>
          <v-list-item-group v-model="selectedGroupNav">
            <v-list-item v-for="(item, i) in groupNavItems" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <v-main v-if="isLoading">
      <template>
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <div class="grey--text">
                <button disabled>Loading Please wait</button>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-main>

    <v-main v-else>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-footer color="blue-grey darken-3 white--text" app>
      <div class="re-direc  mx-auto">
        Powered by
        <a class="rd-link" target="_blank" href="https://ledgermail.io">Ledgermail</a>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      search: "",
      selectedMainNav: 0,
      mainNavItems: [
        { icon: "mdi-inbox", text: "inbox" },
        { icon: "mdi-send", text: "sent" },
        // { icon: "mdi-chart-donut", text: "archives" },
      ],
      selectedGroupNav: null,
      groupNavItems: [],
    };
  },
  created() {
    this.$store
      .dispatch("MAIL_INIT", { emailId: this.userInfo.emailId })
      .then(() => {
        if (this.$route.name == "sent") this.selectedMainNav = 1;
      });
  },
  methods: {
    logout() {
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        this.$router.push("/auth/login");
      });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.initMailStatus == "loading" ? true : false;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.rd-link {
  color: inherit !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
